<template>
  <Page title="Stage" color="grey">
    <template slot="extension">
      <v-tabs
        slider-color="white"
        background-color="grey darken-1"
        :align-with-title="!$vuetify.breakpoint.xs"
      >
        <v-tab :to="{ name: 'PlacementInstitutions' }">Orte </v-tab>
        <v-tab :to="{ name: 'PlacementPlaces' }">Plätze</v-tab>
        <v-tab :to="{ name: 'PlacementAllocation' }">Zuteilung</v-tab>
        <v-tab :to="{ name: 'PlacementVisits' }">Besuche</v-tab>
        <v-tab :to="{ name: 'PlacementPortfolios' }">Portfolios</v-tab>
      </v-tabs>
    </template>
    <router-view></router-view>
  </Page>
</template>

<script>
export default {
  data() {
    return {
      search: "",
    };
  },
  created() {
    if (this.$route.name == "Placement") {
      const view = localStorage.getItem("PlacementView");
      if (view) {
        this.$router.replace({ name: view });
      } else {
        this.$router.replace({ name: "PlacementInstitutions" });
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this.$route.name != "Placement") {
      localStorage.setItem("PlacementView", to.name);
    }
    next();
  },
};
</script>
